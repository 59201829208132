import React from 'react'
import './footer.css'

const footer = () => {
  return (
    <div>
        <p className='footer'>2022 - Zikang (Khan) Zheng </p>
    </div>
  )
}

export default footer