import React from 'react'
import {Container, Row, Col, Tab, Nav} from 'react-bootstrap'
import Tabs from 'react-bootstrap/Tabs';
import Menu from '../menu/Menu.jsx'
import ScrollToTop from '../scrolltotop/ScrollToTop'
import './portfolio.css'
import { PortfolioTab } from './PortfolioTab'
import project1 from './images/artworks/project/1.png'
import project2 from './images/artworks/project/2.jpg'
import project3 from './images/artworks/project/3.jpg'
import project4 from './images/artworks/project/4.jpeg'
import project5 from './images/artworks/project/5.png'
import design1 from './images/artworks/design/1.jpg'
import design2 from './images/artworks/design/2.png'
import artwork1 from './images/artworks/artwork/1.jpg'
import photo1 from './images/artworks/photo/1.JPG'
import photo2 from './images/artworks/photo/2.jpg'
import photo3 from './images/artworks/photo/3.jpg'
import photo4 from './images/artworks/photo/4.jpg'

//import bgImg from './images/bg.jpeg'
// import 'bootstrap/dist/css/bootstrap.min.css';
import {
BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom"


const Portfolio = () => {
  const portfolio1 = [
    {
      id: 1,
      title: "Dietary Note",
      description: "Personal Project: Object Detection/Image Recognition application made by React Native.",
      imgUrl: project1,
      type: "URL",
    },
    {
      id: 2,
      title: "Covid-Omega",
      description: "BC Game Jam 2022 Unreal and Unity Group Project: Map developer, SFX & Audio, and UI developer.",
      imgUrl: project2,
      type: "URL",
    },
    {
      id: 3,
      title: "Hi.re",
      description: "UQHackathon Group Project: Group Leader & Project designer.",
      imgUrl: project3,
      type: "Page",
    },
    {
      id: 4,
      title: "Rialto",
      description: "nwHack2021 Project: Main Developer. Figma for UI design and HTML, NodeJS and MySQL for program development.",
      imgUrl: project4,
      type: "URL",
    },
    {
      id: 5,
      title: "Best Buy Rewards",
      description: "2020 Bizhack Django Project: Group Leader & Project designer.",
      imgUrl: project5,
      type: "URL",
    }]

    const portfolio2 = [
      {
        id: 6,
        title: "Swan Ring",
        description: "The first ring in my product list. Procreate + 3D model.",
        imgUrl: design1,
        type: "Page",
      },
      {
        id: 7,
        title: "Z Samurai",
        description: "Inspired by game Ghost of Tsushima. The design stands for a samurai and name flag. It is also because my hobby in Kyudo.",
        imgUrl: design2,
        type: "Page",
      }]

      const portfolio3 = [
      {
        id: 8,
        title: "Converse | Blood Sport",
        description: "Concerse shoe design inspired by CSGO skin AK47 Blood Sport.",
        imgUrl: artwork1,
        type: "Img",
  
      }]

      const portfolio4 = [
        { 
          id: 9,
          title: "Self Portait",
          description: "Self Protrait",
          imgUrl: photo1,
          type: "Img",
    
        },
        {
          id: 10,
          title: "Fireworks",
          description: "ND 1000 + Tarmon 28-75mm G2 + SONY A7IV @ English Bay",
          imgUrl: photo2,
          type: "Img",
        },
        {
          id: 11,
          title: "Wide Angle Portait",
          description: "14mm is my favorite focus range @ Downtown Vancouver shot by LAOWA 14mm f4 + A7IV",
          imgUrl: photo3,
          type: "Img",
        },
        {
          id: 12,
          title: "120 Color Film",
          description: "I shoot with Yashica MAT 124G TLR + Kodak portra 400",
          imgUrl: photo4,
          type: "Img",
        }]

  return (
    <>
    <div className="menu">
      <Menu />
    </div>
    <div className="portfolio">
      <section className='protfolio'>
      <Container>
        <Row>
          <Col>
            <h2>Profolio</h2>
            <p>Portfolio is also in PDF. Coming soon....</p>
            <Tabs
              defaultActiveKey="profile"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
            <Tab eventKey="tab_1" title="Projects">
              <div className= "tab-details">
                {
                    portfolio1.map((portfolio1, index) =>{
                        return(
                            <PortfolioTab
                            key={index}
                            {...portfolio1}
                            />
                        )
                    })
                }
              </div>
            </Tab>
            <Tab eventKey="tab_2" title="Designs">
              <div className= "tab-details">
                {
                    portfolio2.map((portfolio2, index) =>{
                        return(
                            <PortfolioTab
                            key={index}
                            {...portfolio2}
                            />
                        )
                    })
                }
              </div>
            </Tab>
            <Tab eventKey="tab_3" title="Artworks">
              <div className= "tab-details">
                {
                    portfolio3.map((portfolio3, index) =>{
                        return(
                            <PortfolioTab
                            key={index}
                            {...portfolio3}
                            />
                        )
                    })
                }
              </div>
            </Tab>
            <Tab eventKey="tab_4" title="Photos">
              <div className= "tab-details">
                {
                    portfolio4.map((portfolio4, index) =>{
                        return(
                            <PortfolioTab
                            key={index}
                            {...portfolio4}
                            />
                        )
                    })
                }
              </div>
            </Tab>
    </Tabs>
          </Col>
        </Row>
      </Container>
      {/*<img className="background-img-right" src={bgImg}></img>*/}
      </section>
      <ScrollToTop/>
    </div>
    </>
  )
}

export default Portfolio