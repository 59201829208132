import React, { useState } from 'react'
import { AiOutlineCloseSquare } from 'react-icons/ai'

export const ImageCom = ({ imgUrl , showImgVal, setSeenState}) => {
  return (
    <>
      { showImgVal? 
      <div className='subview-com'>
      <AiOutlineCloseSquare className='subview-close' onClick={()=> setSeenState(false)}/>
      <img className='subview-image' src={imgUrl} />
      </div>: <></>}
    </>
  )
}
