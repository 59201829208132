import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {BsGithub} from 'react-icons/bs'
import {BsInstagram} from 'react-icons/bs'
import {SiBilibili} from 'react-icons/si'
import './socials.css'

const Socials = () => {
  return (
    <div className="socials">
        <a href="https://www.linkedin.com/in/zikang-z-b5a19b127/" target="_blank"><BsLinkedin /></a>
        <a href="https://github.com/khan123451" target="_blank"><BsGithub /></a>
        <a href="https://www.instagram.com/zucchinigx/" target="_blank"><BsInstagram /></a>
        <a href="https://space.bilibili.com/4128839" target="_blank"><SiBilibili /></a>
    </div>
  )
}

export default Socials