import React from 'react'
import Menu from './components/menu/Menu.jsx'
import Biography from './components/biography/Biography.jsx'
import Contact from './components/contact/Contact.jsx'
import Blogs from './components/Blogs'
import Portfolio from './components/portfolio/Portfolio.jsx'
import './App.css'
import {
BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom"


function App() {
  return (
    <Router>
      <Routes>
          <Route path="/" element={<Menu />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/biography" element={<Biography/>} />
          <Route path="/contact" element = {<Contact/>} />
          <Route index element={<Blogs />} />
      </Routes>
    </Router>
  )
}
export default App