import React, { useState } from 'react'
import { AiOutlineCloseSquare } from 'react-icons/ai'
import p_p1 from './pageImg/1.jpg'
import p_p2 from './pageImg/2.jpeg'
import p_p3 from './pageImg/3.jpeg'
import p_p4 from './pageImg/4.jpeg'
import p_p5 from './pageImg/5.jpeg'
import p_p6 from './pageImg/6.jpeg'
import p_p7 from './pageImg/7.jpeg'
import p_p8 from './pageImg/8.jpeg'
import p_p9 from './pageImg/9.jpeg'
import p_p10 from './pageImg/10.jpeg'
import p_p11 from './pageImg/11.jpeg'

export const PageCom = ({ idVal , title, showPage, setSeenState }) => {
  const pages = [
    {
      id: 3,
      imgUrl: [
        p_p1,
      ],
      videoURL:"https://www.youtube.com/watch?v=3cw04hcPo8k",
      webURL:"https://github.com/khan123451/UQHackathon2020",
      description: "The icon is inspired by Google icon. We want to make a powerful AI job searching website that is useful as search engine Google during the hackathon. For more details, please check out the Video uploaded on Youtube and my Github Repository.",
    },
    {
      id: 6,
      imgUrl: [
        p_p2,
        p_p3,
        p_p4,
        p_p5,
        p_p6,
        p_p7,
        p_p8,
      ],
      videoURL:"",
      webURL:"",
      description: "Black swan metal ring. The ring is inspired by the fairy tale of the ugly duckling. Black swan is rare in the animal world. In the fairy tale, they need to face up difficulties and finally become swan. The owner of this ring should always remind themselves that they need to face diffculties in order to achieve success. To illustrate the icons on the ring, the waves stands for troubles, and the black swan is trying to fly and against the large waves. It was first start on draft drawing then 3D model, and had few changes with the ring manufacturer. This is the finally product.",
    },
    {
      id: 7,
      imgUrl: [
        p_p9,
        p_p10,
        p_p11,
      ],
      videoURL:"",
      webURL:"",
      description: "Z Samurai. This is inspired by the game: Ghost of Tsushima. The Samurai stands for my hobby in Japanese archery, Kyudo. It is a traditional martial art for Samurai to learn, and the character on the flag is used to stands for the family or Shogun (Japanese military group) the person allegiance with. Here 鄭 (Zheng) is my Surname in Traditional Chinese. I have made this design on bags, umbrella, or sealing wax stamps.",
    },
  ];



  return (
    <>
      { showPage? 
      <div className='subview-com'>
      <AiOutlineCloseSquare className='subview-pageclose' onClick={()=> setSeenState(false)}/>
      <div className='subview-pageDetail'>
        <br />
      <h3 className='subview-title'>{title}</h3>
      {pages.map(function(e){
        if(e.id == idVal){
          return (
          <>
          {e.imgUrl.map(imageAddr =>(
          <img className='subview-images' src={imageAddr} alt="" />
          ))}
          <h5>{e.description}</h5>
          {e.videoURL !==""? <><br /><h5 className='subview-text'>Youtube Link: <a href={e.videoURL}>{e.videoURL}</a></h5></>: null}
          {e.webURL !==""? <><br /><h5 className='subview-text'>Github Link: <a href={e.webURL}>{e.webURL}</a></h5></>: null}
          </>);
        }
      })
      }
      </div>
      </div>: <></>}
    </>
  )
}
