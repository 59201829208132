import React from 'react'
import './menu.css'
import Image from './image/Image.jsx'
import resume from './resume.pdf'
import Socials from './socials/Socials.jsx'
import { Link, BrowserRouter as Router, Outlet, Route } from 'react-router-dom'
//import Portfolio from '../portfolio/Portfolio.jsx'


const Menu = () => {

  const onButtonClick = () => {
    fetch(resume).then(response => {
      response.blob().then(cv => {
        const fileURL = window.URL.createObjectURL(cv)
        let cvlink = document.createElement('a')
        cvlink.href = fileURL
        cvlink.download = resume
        cvlink.click()
      })
    })
  }

  
  
  return (
    <>
    <nav className='menu-nav'>
      <ul className= "sidebar">
        <li className="avatar-image">
            <Image />
        </li>
        <li> 
          <p className= "name">Khan Zheng</p>
          <p className= "detail"> Program Analyst @UBC</p>
        </li>
        <li>
          <a href="/#biography">Biography</a>
        </li>
        <li>
          < button onClick={onButtonClick}>CV</button>
        </li>
        <li>
          <Link to= "/portfolio" className="portfolio_page">Portfolio</Link>
        </li>
        <li>
          <a href= "/#contact" className="contact_me">Contact Me</a>
        </li>
        <li>
          <div className="socialBar">
            <Socials />
          </div>
        </li>
      </ul>
      </nav>

      <Outlet />
    </>
  )
}

export default Menu