import React from 'react'
import './biography.css'
import Biography_TXT from './biography.txt'
import Biography_TITLE from './biography_title.txt'

const Biography = () => {

  const [title, setTitle] = React.useState()
  fetch(Biography_TITLE)
  .then((response) => response.text())
  .then((res) => {
          setTitle(res)
        })

  const [text, setText] = React.useState()
  fetch(Biography_TXT)
  .then((response) => response.text())
  .then((res) => {
                      setText(res)
                    })
  
  return (
    <>
    <section className='biography' id='biography'>
        <div>
          <legend>{title}</legend>
          <p className="biography">
            &emsp; &emsp; {text}
          </p>
        </div>
      </section>
    </>)
}

export default Biography