import React, { useState } from 'react'
import { Col } from 'react-bootstrap'
import { renderMatches } from 'react-router'
import { ImageCom } from './subview/Image'
import { PageCom } from './subview/Page'

function redirectHelper(id) {
  var url = "";
  switch(id) {
    case 1:
      url = "https://github.com/khan123451/calories-cal/tree/main/";
      break;
    case 2:
      url = "https://vent0s.itch.io/covid-omega";
      break;
    case 4:
      url = "https://github.com/khan123451/nwhack2021";
      break;
    case 5:
      url = "https://github.com/khan123451/bizhack2020";
      break;
    default:
      break;
  }
  window.location.assign(url);
}


export const PortfolioTab = ({ id, title, description, imgUrl, type }) => {
  const [seenState, setSeenState] = useState(false)

  const redirectImgHelper = ({ imgUrl }) => {
    return (
      <img src={imgUrl} />
    );
  }

  if(seenState){
    switch(type){
      case 'URL':
          redirectHelper(id);
        break;
      default:
        break;
    }

  }
  return (
      <Col sm={6} md={4}>
          { (seenState && type == 'Img')? <ImageCom imgUrl = {imgUrl} showImgVal = {seenState} setSeenState={setSeenState}/>: <></> }
          { (seenState && type == 'Page')? <PageCom idVal = {id} title={title} showPage = {seenState} setSeenState={setSeenState}/>: <></>}
          <div className="portfolio-imgbx">
          <img className="portfolio-img-prop" src={imgUrl} />
          <div className="portfolio-tx" onClick={()=>setSeenState(true)}>
              <h5 className="portfolio-title">{title}</h5>
              <span>{description}</span>
              {type == "URL"? <><br /><span style={{color: "blue"}}> Click to Check Out </span></> : <></>}
          </div>
          </div>
      </Col>
  )
}
