import React from 'react'
import bgImg from './image/bg.jpg'
import './frontimg.css'

const FrontImg = () => {
  return (
    <>
    <img className='background-img' src={bgImg}/>
    </>
  )
}

export default FrontImg