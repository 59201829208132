import React, { useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import './contact.css'

const Contact = () => {
  const p_form = {
    firstName: '',
    lastName: '',
    prefName: '',
    email: '',
    phone: '',
    message: ''
  }

  const [formDetails, setForm] = useState(p_form)
  const [buttonText, setButtonText] = useState('Send')
  const [status, setStatus] = useState({})

  const onFormUpdate = (category, value) => {
    setForm({
      ...formDetails,
      [category]: value
    })
  }

  const handleSubmit = async (e) => {
    try{
      e.preventDefault()
      setButtonText("Sending...")
      let response = await fetch("/contact", {
        method: "POST",
        headers: {
          "Contact-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(formDetails),
      })
      setButtonText("Send")
      let result = await response.json()
      setForm(p_form)
      if (result.code === 200) {
        setStatus({ success: true, message: 'Message sent sucessfully!'})
      } else {
        setStatus({ success: false, message: 'Error, please try again!'})
      }
    } catch (error) {
      console.log(error)
    }
    
  }

  return (
    <section className='contact' id='contact'>
      <Container>
        <Row className='align-items-center'>
          <Col size={12} md={6}>
            <h2 className='contacttitle'>Keep In Touch</h2>
            <form onSubmit={handleSubmit}>
              <Row>
                <Col size={12} md={6} className="px-1">
                  <input type="text" value={formDetails.firstName} placeholder= "First Name" onChange={(e) => onFormUpdate('firstName', e.target.value)} />
                </Col>
                <Col size={12} md={6} className="px-1">
                  <input type="text" value={formDetails.lastName} placeholder= "Last Name" onChange={(e) => onFormUpdate('lastName', e.target.value)} />
                </Col>
                <Col size={12} md={6} className="px-1">
                  <input type="text" value={formDetails.prefName} placeholder= "Preferred Name" onChange={(e) => onFormUpdate('prefName', e.target.value)} />
                </Col>
                <Col size={12} md={6} className="px-1">
                  <input type="email" value={formDetails.email} placeholder= "Email Address" onChange={(e) => onFormUpdate('email', e.target.value)} />
                </Col>
                <Col size={12} md={6} className="px-1">
                  <input type="tel" value={formDetails.phone} placeholder= "Phone No." onChange={(e) => onFormUpdate('phone', e.target.value)} />
                </Col>
                <Col size={12} md={6} className="px-1">
                  <textarea rows="6" value={formDetails.message} placeholder= "Message" onChange={(e) => onFormUpdate('message', e.target.value)}></textarea>
                  <button type="submit"><span>{buttonText}</span></button>
                </Col>
                {
                  status.message &&
                  <Col>
                    <p className={status.success === false ? "danger" : "success"}>{status.message}</p>
                  </Col>
                }
              </Row>
            </form>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Contact