import React from 'react'
import './image.css'
import avatar from './avatar.jpg'

function Image() {
  return (
    <img src={avatar} alt="Avatar" className='avatar'/>
  )
}

export default Image