import React from 'react'
import Menu from './menu/Menu'
import FrontImg from './frontimg/FrontImg'
import Biography from './biography/Biography'
import Contact from './contact/Contact'
import Footer from './footer/Footer'
import ScrollToTop from './scrolltotop/ScrollToTop'
import './blogs.css'

const blogs = () => {
  return (
    <>
    <div className="menu">
      <Menu />
    </div>
        <div className='blogs'>
            <FrontImg />
            <Biography />
            <Contact />
            <Footer />
            <ScrollToTop/>
        </div>
    </>
  )
}

export default blogs